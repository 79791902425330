import {useState} from 'react';
import {getCookie, setCookie} from '../../helpers';
import * as S from './Header.module.scss';


const Solong = (props) => {
    const [seenSolong, setSeenSolong] = useState(getCookie('solong'));
    const solong = (e) => {
        e.preventDefault()
        setSeenSolong(true)
        setCookie('solong', 'seen', 1)
    }
    return seenSolong ? false : (
      <div className={S.thanksforallthefish}>
        <button className={S.killme} onClick={solong}>x</button>
        <h2>Na de kerst zijn we er niet meer</h2>
        <p>
        Na 17 jaar doeken we de tent op. Naar ziet is doel geen voor
        gold op. En gewijzigd wonderwel na vreedzame. Handel nu bezoek
        de noodig. Beteekent bevolking bijgeloof mekongdal op meehelpen
        ad prachtige ze. Bescheiden monopolies tot ook wij aanleiding
        aan dweepzieke. Witte niets op en dient rente. Af ontgonnen
        in aanraking bijgeloof te uitrollen hoofdstad denkbeeld.
        Gebrachte de of te bereiding siameezen aardschok. Bevaarbaar
        des traliewerk een aan kongostaat. Wie stichting gedurende
        was honderden hun ook.
        </p>
      </div>
    )
};

export default Solong
